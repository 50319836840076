<script>
    import Header from '@/template/header.svelte';
    import Footer from '@/template/footer.svelte';
    import SvelteMarkdown from 'svelte-markdown';

    const complaintsMarkdown = `
# Complaints and Content Removal Policy

We are committed to maintaining a safe, respectful, and compliant community. This policy outlines our procedures for addressing complaints and removing content that violates our terms of service, community standards, or legal requirements.

**Important: All complaints and appeals will be reviewed and resolved within 7 business days.**

## 1. Reporting Content

If you encounter content that you believe violates our community standards or legal restrictions, e.g. if you are depicted improperly in content, please report it immediately using one of the following methods:

- Use the "Report" button available on all content pieces and user profiles, or directly contact us through one of the content channels mentioned in Section 5
- Specify the reason for your report to help expedite the process.
- Provide any additional context that may assist in our review.

### 1.1 On-Platform Reports

You can report both posts and profiles directly via the platform's interface. The system provides support agents with direct context (the specific post or profile in question). You are advised to describe your complaint sufficiently in the provided text field to ensure a comprehensive review.

### 1.2 Manual Inquiries and Complaints

You can also submit manual inquiries or complaints regarding content removal directly to our support team. See section 5 for different communication channels we offer. When submitting a manual inquiry, please provide as much identifying information about the reported content as possible, including:

- Identifying information about the user responsible for the reported content (Link to profile/Post)
- A short description detailing the problem with the reported content.
- The exact date and time you encountered the reported content on the platform.

We strongly encourage you to provide as comprehensive information as possible to facilitate a swift and accurate review.

## 2. Review Process

### 2.1 On-Platform Reports

#### Real Time Action
Posts that accumulate a certain number of reports from trusted accounts are immediately shadowbanned (hidden from users) and queued for manual review.

##### Trusted Accounts
Accounts with a specified age and transaction volume are designated as trusted. The specific thresholds for trusted status are periodically adjusted based on platform data and trends.

#### Manual Review
A human agent examines the reported content as expeditiously as possible to determine if it contravenes our policies.

#### Decision
Following the review, a determination is made to either reinstate the content or pursue further action.

### 2.2 Manual Inquiries and Complaints

#### Initial Review
A support agent will assess the inquiry as promptly as possible, with a maximum response time of 7 business days.

#### Information Gathering
Depending on the initial context provided, we may request additional details from you. It is in your best interest to supply thorough context to facilitate a swift and accurate review.

#### Precautionary Measures
Given the sensitive nature of the industry, we prioritize user safety. When uncertainty arises regarding the nature or impact of reported content, we take a proactive approach by temporarily withholding content until a full review can be completed. This measure is implemented to ensure the protection of all parties involved.

#### Final Decision
Upon gathering all necessary information and conducting a thorough review, a final decision will be rendered regarding the reported content. This may result in content restoration, or further consequences (see section 3 for more information).
## 3. Actions Following Review

Depending on the severity of the violation, we may take the following actions:

- **Minor Violations:** Content removal and a warning to the content provider.
- **Medium Violations:** Content removal and a strike issued to the account. Accounts can receive a maximum of 3 strikes per calendar year.
- **Severe Violations:** Immediate account ban and possible proactive reporting to legal authorities.

We will inform you of these outcomes of these complaints.

## 4. Content Removal and Moderation

We have several controls in place to moderate content and align it with our platform requirements:

- **Post Removal:** Ability to remove individual posts that violate our policies.
- **Account Strikes:** Issuing warnings and strikes to accounts for policy violations.
- **Account Bans:** Permanently banning accounts for severe or repeated violations.
- **Content Flagging:** Ability to flag posts or profiles for review, hiding them until the review is completed.

## 5. Complaints

If you would like to file a complaint, you have two options:

1. Email us at [complaints@buymynudes.com](mailto:complaints@buymynudes.com)
2. Submit a complaint through our support website at [support.buymynudes.com](https://support.buymynudes.com)

All complaints will be reviewed and resolved within 7 business days and you will receive a response/email once it has been done. We will, in accordance with our terms and conditions, decide if the complaint is valid. After the decision has been made, the user that complained can appeal that decision. When the final decision is made, according to us, any infringing, illegal, abusive or otherwise inappropriate content will be removed from the website.

## 6. Appeals

If you have been depicted in any content and would like to appeal to remove any such content, you can:

1. Email us at [appeals@buymynudes.com](mailto:appeals@buymynudes.com)
2. Submit an appeal through our support website at [support.buymynudes.com](https://support.buymynudes.com)

If there should be a disagreement regarding this appeal, we will allow the disagreement to be resolved by a neutral body.


## 7. Appealing Decisions

We strive for fairness and accuracy in all our decisions. However, we recognize that where humans are involved, there's always a possibility of error. Therefore, we have established a robust appeals process to ensure that all parties have the opportunity to present their case fully.

### 7.1 Notification of Decisions

When a decision is made regarding a complaint or content removal, both the person who reported the content and the affected content creator will be notified promptly. This notification will include the details of the decision and the reasoning behind it.

### 7.2 Initial Appeal Process

If you believe that a decision made by our support team is incorrect, you have the right to appeal. To initiate an appeal:

1. Respond to the decision notification email or contact [appeals@buymynudes.com](mailto:appeals@buymynudes.com)
2. Provide any new evidence or information that supports your case
3. Clearly explain why you believe the decision should be reconsidered

Our support team will review your appeal and respond within 7 business days.

### 7.3 Secondary Neutral Review

If you feel that you cannot reach a consensus with our support staff through the initial appeal process, we offer a secondary neutral review option. This ensures an unbiased evaluation of your case.

To request a neutral review:

1. Email [neutral@buymynudes.com](mailto:neutral@buymynudes.com)
2. Include all relevant information and previous correspondence
3. Explain why you believe a neutral review is necessary

The neutral review is conducted by individuals not affiliated with our regular support team, ensuring an impartial assessment of your case.

### 7.4 Final Decision

The decision reached after the neutral review will be considered final. We commit to implementing this decision promptly and fairly.

### 7.5 Continuous Improvement

We value the feedback received through the appeals process as it helps us refine our policies and procedures. While we always aim for correct decisions, we appreciate your understanding that errors can occur. Your engagement in this process helps us maintain a fair and transparent platform for all users.

## 8. Changes to This Policy

We may revise this policy periodically to reflect changes in our practices, technologies, or relevant regulations. Users are encouraged to review this policy regularly to stay informed of any updates.

## 9. Contact Information

For any further questions or concerns regarding our complaints and content removal policy, please contact our support team through one of the following methods:

- Email: [support@buymynudes.com](mailto:support@buymynudes.com)
- Support Website: [support.buymynudes.com](https://support.buymynudes.com)

By using our platform, you agree to abide by this policy and our terms of service. We are committed to maintaining a safe and compliant environment for all our users and content creators.
`;
</script>

<Header />
<main class="prose mx-auto flex max-w-7xl flex-col px-4 py-8 text-black dark:text-white sm:px-6 lg:px-8">
    <SvelteMarkdown source="{complaintsMarkdown}" />
</main>
<Footer />
